import { Component, DestroyRef, OnDestroy, OnInit } from '@angular/core';
import * as tf from '@tensorflow/tfjs';
import * as knnClassifier from '@tensorflow-models/knn-classifier';
import * as mobilenet from  '@tensorflow-models/mobilenet';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-ejecutar-modelo',
  standalone: true,
  imports: [],
  templateUrl: './ejecutar-modelo.component.html'
})
export class EjecutarModeloComponent implements OnInit, OnDestroy{
  classifier: knnClassifier.KNNClassifier;
  url:string=environment.apiUrl;
  rutaUrl!: string;
  resultado!: any;
  consultaLabels: any;
  labels= [];
  private isAlive = true;
  constructor(public route: ActivatedRoute, public http: HttpClient){
    this.classifier = knnClassifier.create();
    this.route.params.subscribe(async id=>{
      this.rutaUrl = this.url+"/uploads/"+id["id"];
      this.resultado = await fetch(this.rutaUrl+".txt")
      this.resultado = await this.resultado.text()
      this.classifier.setClassifierDataset(Object.fromEntries(JSON.parse(this.resultado).map(([label, data, shape]:any) => [label, tf.tensor(data, shape)])));
      this.consultaLabels = await fetch(this.rutaUrl+".json")
      this.consultaLabels = await this.consultaLabels.text()
      let labelJson = JSON.parse(this.consultaLabels)
      this.labels=labelJson.labels.split(",")
    });
    this.app();
  }
  ngOnDestroy(){
    this.isAlive=false;
  }
  ngOnInit() {
    this.videoRef = document.getElementById("webcam");
    console.log(this.videoRef);
    this.setupCamera();

}
  net: any;
  // classifier = knnClassifier.create() ;
  webcam: any;
  videoRef: any;
  async app(){
      this.net = await mobilenet.load();
      this.webcam = await tf.data.webcam(this.videoRef);
      while(this.isAlive){
          const img = await this.webcam.capture();
          const result = await this.net.classify(img);
          const activation = this.net.infer(img, "conv_preds");
          let result2:any;
          try {
              result2 = await this.classifier.predictClass(activation);
              console.log(result2)
              const classes = ["No definido"].concat(this.labels)
              document.getElementById("console4")?document.getElementById("console4")!.innerText="Predicción: " + classes[result2.label] + ", Probabilidad: " + (result2.confidences[result2.label].toFixed(2)):""; 
          } catch (error) {
              console.log("Modelo no configurado aún"+error);
              result2= {};
              document.getElementById("console4")?document.getElementById("console4")!.innerText="Modelo no entrenado":"";                   
          }
          try{
          
          }
          catch(err) {
          }
          img.dispose();
          await tf.nextFrame();
          }
  }
  
  setupCamera(){
      navigator.mediaDevices.getUserMedia({
          video: {width: 300, height:250},
          audio: false
      }).then(stream=>{
          console.log(stream);
          this.videoRef.srcObject = stream;
      })
  }

}
