/**
 * @license
 * Copyright 2023 Google LLC.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
import { backend_util, StaticRegexReplace } from '@tensorflow/tfjs-core';
import { staticRegexReplaceImplCPU } from '../kernel_utils/shared';
export function staticRegexReplace(args) {
  const {
    inputs,
    backend,
    attrs
  } = args;
  const {
    x
  } = inputs;
  if (x.dtype !== 'string') {
    throw new Error('Input must be of datatype string');
  }
  const $x = backend.readSync(x.dataId);
  const stringInput = backend_util.fromUint8ToStringArray($x);
  const output = staticRegexReplaceImplCPU(stringInput, 'string', attrs);
  return backend.makeTensorInfo(x.shape, 'string', output);
}
export const staticRegexReplaceConfig = {
  kernelName: StaticRegexReplace,
  backendName: 'webgl',
  kernelFunc: staticRegexReplace
};
