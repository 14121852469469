/**
 * @license
 * Copyright 2019 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
export class AddNProgram {
  constructor(outputShape, shapes) {
    this.outputShape = [];
    this.outputShape = outputShape;
    this.variableNames = shapes.map((_, i) => `T${i}`);
    const snippets = [];
    // Get target elements from every input tensor.
    this.variableNames.forEach(variable => {
      snippets.push(`float v${variable} = get${variable}AtOutCoords();`);
    });
    // Calculate the sum of all elements.
    const operation = this.variableNames.map(variable => {
      return `v${variable}`;
    }).join(' + ');
    this.userCode = `
      void main() {
        ${snippets.join('\n        ')}

        float result = ${operation};
        setOutput(result);
      }
    `;
  }
}
