import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as knnClassifier from '@tensorflow-models/knn-classifier';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModeloService {
  url: string = environment.apiUrl 
  constructor(public http: HttpClient) { }
  data: any;
  getModelos(){
    return this.http.get(this.url+"/models")
  }
  guardarModelos(classifier: knnClassifier.KNNClassifier, nombre: string|null){
    const archivo=JSON.stringify(Object.entries(classifier.getClassifierDataset()).map(([label, data]) => [label, Array.from(data.dataSync()), data.shape]))
    // const archivoTxt  = "text/json;charset=utf-8," + encodeURIComponent(archivo); 
    const archivoTxt  = new Blob([archivo], { type: 'text/plain' }); 
    console.log(archivoTxt)
    const formData = new FormData();
    formData.append('file', archivoTxt, nombre+".txt");
    return this.http.put(this.url+"/models", formData)
  }
  guardarJsonModelo(labels:string, nombre: string) {
    const archivo=JSON.stringify({"labels":labels})
    // const archivoTxt  = "text/json;charset=utf-8," + encodeURIComponent(archivo); 
    const archivoJson  = new Blob([archivo], { type: 'application/json' }); 
    console.log(archivoJson)
    const formData = new FormData();
    formData.append('file', archivoJson, nombre+".json");
    return this.http.post(this.url+"/models", formData)
  }
}
