/**
 * @license
 * Copyright 2020 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
// Modularized ops.
export { abs } from './abs';
export { acos } from './acos';
export { acosh } from './acosh';
export { add } from './add';
export { addN } from './add_n';
export { all } from './all';
export { any } from './any';
export { argMax } from './arg_max';
export { argMin } from './arg_min';
export { asin } from './asin';
export { asinh } from './asinh';
export { atan } from './atan';
export { atan2 } from './atan2';
export { atanh } from './atanh';
export { avgPool } from './avg_pool';
export { avgPool3d } from './avg_pool_3d';
export { basicLSTMCell } from './basic_lstm_cell';
export { batchToSpaceND } from './batch_to_space_nd';
export { batchNorm } from './batchnorm';
export { batchNorm2d } from './batchnorm2d';
export { batchNorm3d } from './batchnorm3d';
export { batchNorm4d } from './batchnorm4d';
export { bincount } from './bincount';
export { bitwiseAnd } from './bitwise_and';
export { broadcastArgs } from './broadcast_args';
export { broadcastTo } from './broadcast_to';
export { buffer } from './buffer';
export { cast } from './cast';
export { ceil } from './ceil';
export { clipByValue } from './clip_by_value';
export { clone } from './clone';
export { complex } from './complex';
export { concat } from './concat';
export { concat1d } from './concat_1d';
export { concat2d } from './concat_2d';
export { concat3d } from './concat_3d';
export { concat4d } from './concat_4d';
export { conv1d } from './conv1d';
export { conv2d } from './conv2d';
export { conv2dTranspose } from './conv2d_transpose';
export { conv3d } from './conv3d';
export { conv3dTranspose } from './conv3d_transpose';
export { cos } from './cos';
export { cosh } from './cosh';
export { cumprod } from './cumprod';
export { cumsum } from './cumsum';
export { denseBincount } from './dense_bincount';
export { depthToSpace } from './depth_to_space';
export { depthwiseConv2d } from './depthwise_conv2d';
export { diag } from './diag';
export { dilation2d } from './dilation2d';
export { div } from './div';
export { divNoNan } from './div_no_nan';
export { dot } from './dot';
export { einsum } from './einsum';
export { elu } from './elu';
export { ensureShape } from './ensure_shape';
export { equal } from './equal';
export { erf } from './erf';
export { euclideanNorm } from './euclidean_norm';
export { exp } from './exp';
export { expandDims } from './expand_dims';
export { expm1 } from './expm1';
export { eye } from './eye';
export { fill } from './fill';
export { floor } from './floor';
export { floorDiv } from './floorDiv';
export { gather } from './gather';
export { greater } from './greater';
export { greaterEqual } from './greater_equal';
export { imag } from './imag';
export { isFinite } from './is_finite';
export { isInf } from './is_inf';
export { isNaN } from './is_nan';
export { leakyRelu } from './leaky_relu';
export { less } from './less';
export { lessEqual } from './less_equal';
export { linspace } from './linspace';
export { localResponseNormalization } from './local_response_normalization';
export { log } from './log';
export { log1p } from './log1p';
export { logSigmoid } from './log_sigmoid';
export { logSoftmax } from './log_softmax';
export { logSumExp } from './log_sum_exp';
export { logicalAnd } from './logical_and';
export { logicalNot } from './logical_not';
export { logicalOr } from './logical_or';
export { logicalXor } from './logical_xor';
export { lowerBound } from './lower_bound';
export { matMul } from './mat_mul';
export { max } from './max';
export { maxPool } from './max_pool';
export { maxPool3d } from './max_pool_3d';
export { maxPoolWithArgmax } from './max_pool_with_argmax';
export { maximum } from './maximum';
export { mean } from './mean';
export { meshgrid } from './meshgrid';
export { min } from './min';
export { minimum } from './minimum';
export { mirrorPad } from './mirror_pad';
export { mod } from './mod';
export { moments } from './moments';
export { mul } from './mul';
export { multiRNNCell } from './multi_rnn_cell';
export { multinomial } from './multinomial';
export { neg } from './neg';
export { notEqual } from './not_equal';
export { oneHot } from './one_hot';
export { ones } from './ones';
export { onesLike } from './ones_like';
export { outerProduct } from './outer_product';
export { pad } from './pad';
export { pad1d } from './pad1d';
export { pad2d } from './pad2d';
export { pad3d } from './pad3d';
export { pad4d } from './pad4d';
export { pool } from './pool';
export { pow } from './pow';
export { prelu } from './prelu';
export { print } from './print';
export { prod } from './prod';
export { raggedGather } from './ragged_gather';
export { raggedRange } from './ragged_range';
export { raggedTensorToTensor } from './ragged_tensor_to_tensor';
export { rand } from './rand';
export { randomGamma } from './random_gamma';
export { randomNormal } from './random_normal';
export { randomStandardNormal } from './random_standard_normal';
export { randomUniform } from './random_uniform';
export { randomUniformInt } from './random_uniform_int';
export { range } from './range';
export { real } from './real';
export { reciprocal } from './reciprocal';
export { relu } from './relu';
export { relu6 } from './relu6';
export { reshape } from './reshape';
export { reverse } from './reverse';
export { reverse1d } from './reverse_1d';
export { reverse2d } from './reverse_2d';
export { reverse3d } from './reverse_3d';
export { reverse4d } from './reverse_4d';
export { round } from './round';
export { rsqrt } from './rsqrt';
export { scalar } from './scalar';
export { selu } from './selu';
export { separableConv2d } from './separable_conv2d';
export { setdiff1dAsync } from './setdiff1d_async';
export { sigmoid } from './sigmoid';
export { sign } from './sign';
export { sin } from './sin';
export { sinh } from './sinh';
export { slice } from './slice';
export { slice1d } from './slice1d';
export { slice2d } from './slice2d';
export { slice3d } from './slice3d';
export { slice4d } from './slice4d';
export { softmax } from './softmax';
export { softplus } from './softplus';
export { spaceToBatchND } from './space_to_batch_nd';
export { fft } from './spectral/fft';
export { ifft } from './spectral/ifft';
export { irfft } from './spectral/irfft';
export { rfft } from './spectral/rfft';
export { split } from './split';
export { sqrt } from './sqrt';
export { square } from './square';
export { squaredDifference } from './squared_difference';
export { squeeze } from './squeeze';
export { stack } from './stack';
export { step } from './step';
export { stridedSlice } from './strided_slice';
export { sub } from './sub';
export { sum } from './sum';
export { tan } from './tan';
export { tanh } from './tanh';
export { tensor } from './tensor';
export { tensor1d } from './tensor1d';
export { tensor2d } from './tensor2d';
export { tensor3d } from './tensor3d';
export { tensor4d } from './tensor4d';
export { tensor5d } from './tensor5d';
export { tensor6d } from './tensor6d';
export { tensorScatterUpdate } from './tensor_scatter_update';
export { tile } from './tile';
export { topk } from './topk';
export { truncatedNormal } from './truncated_normal';
export { unique } from './unique';
export { unsortedSegmentSum } from './unsorted_segment_sum';
export { unstack } from './unstack';
export { upperBound } from './upper_bound';
export { variable } from './variable';
export { where } from './where';
export { whereAsync } from './where_async';
export { zeros } from './zeros';
export { zerosLike } from './zeros_like';
export * from './boolean_mask';
export * from './transpose';
export * from './norm';
export * from './moving_average';
export * from './scatter_nd';
export * from './search_sorted';
export * from './sparse_to_dense';
export * from './gather_nd';
export * from './dropout';
export * from './signal_ops_util';
export * from './in_top_k';
export { op, OP_SCOPE_SUFFIX } from './operation';
import { rfft } from './spectral/rfft';
import { fft } from './spectral/fft';
import { ifft } from './spectral/ifft';
import { irfft } from './spectral/irfft';
const spectral = {
  fft,
  ifft,
  rfft,
  irfft
};
import * as fused from './fused_ops';
import { hammingWindow } from './signal/hamming_window';
import { hannWindow } from './signal/hann_window';
import { frame } from './signal/frame';
import { stft } from './signal/stft';
const signal = {
  hammingWindow,
  hannWindow,
  frame,
  stft
};
// Image Ops namespace
import { cropAndResize } from './image/crop_and_resize';
import { flipLeftRight } from './image/flip_left_right';
import { grayscaleToRGB } from './image/grayscale_to_rgb';
import { rgbToGrayscale } from './image/rgb_to_grayscale';
import { rotateWithOffset } from './image/rotate_with_offset';
import { nonMaxSuppression } from './image/non_max_suppression';
import { nonMaxSuppressionAsync } from './image/non_max_suppression_async';
import { nonMaxSuppressionWithScore } from './image/non_max_suppression_with_score';
import { nonMaxSuppressionWithScoreAsync } from './image/non_max_suppression_with_score_async';
import { nonMaxSuppressionPadded } from './image/non_max_suppression_padded';
import { nonMaxSuppressionPaddedAsync } from './image/non_max_suppression_padded_async';
import { resizeBilinear } from './image/resize_bilinear';
import { resizeNearestNeighbor } from './image/resize_nearest_neighbor';
import { threshold } from './image/threshold';
import { transform } from './image/transform';
const image = {
  flipLeftRight,
  grayscaleToRGB,
  resizeNearestNeighbor,
  resizeBilinear,
  rgbToGrayscale,
  rotateWithOffset,
  cropAndResize,
  nonMaxSuppression,
  nonMaxSuppressionAsync,
  nonMaxSuppressionWithScore,
  nonMaxSuppressionWithScoreAsync,
  nonMaxSuppressionPadded,
  nonMaxSuppressionPaddedAsync,
  threshold,
  transform
};
// linalg namespace
import { bandPart } from './linalg/band_part';
import { gramSchmidt } from './linalg/gram_schmidt';
import { qr } from './linalg/qr';
const linalg = {
  bandPart,
  gramSchmidt,
  qr
};
// losses namespace;
import { absoluteDifference } from './losses/absolute_difference';
import { computeWeightedLoss } from './losses/compute_weighted_loss';
import { cosineDistance } from './losses/cosine_distance';
import { hingeLoss } from './losses/hinge_loss';
import { huberLoss } from './losses/huber_loss';
import { logLoss } from './losses/log_loss';
import { meanSquaredError } from './losses/mean_squared_error';
import { sigmoidCrossEntropy } from './losses/sigmoid_cross_entropy';
import { softmaxCrossEntropy } from './losses/softmax_cross_entropy';
const losses = {
  absoluteDifference,
  computeWeightedLoss,
  cosineDistance,
  hingeLoss,
  huberLoss,
  logLoss,
  meanSquaredError,
  sigmoidCrossEntropy,
  softmaxCrossEntropy
};
import { sparseFillEmptyRows } from './sparse/sparse_fill_empty_rows';
import { sparseReshape } from './sparse/sparse_reshape';
import { sparseSegmentMean } from './sparse/sparse_segment_mean';
import { sparseSegmentSum } from './sparse/sparse_segment_sum';
const sparse = {
  sparseFillEmptyRows,
  sparseReshape,
  sparseSegmentMean,
  sparseSegmentSum
};
import { stringNGrams } from './string/string_n_grams';
import { stringSplit } from './string/string_split';
import { stringToHashBucketFast } from './string/string_to_hash_bucket_fast';
import { staticRegexReplace } from './string/static_regex_replace';
// tslint:disable-next-line:variable-name
const string = {
  stringNGrams,
  stringSplit,
  stringToHashBucketFast,
  staticRegexReplace
};
// Second level exports.
export { image, linalg, losses, spectral, fused, signal, sparse, string };
