/**
 * @license
 * Copyright 2020 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
import { ENGINE } from '../engine';
import { LRNGrad } from '../kernel_names';
import { op } from './operation';
function localResponseNormalizationBackprop_(x, y, dy, depthRadius = 5, bias = 1, alpha = 1, beta = 0.5) {
  const inputs = {
    x,
    y,
    dy
  };
  const attrs = {
    depthRadius,
    bias,
    alpha,
    beta
  };
  return ENGINE.runKernel(LRNGrad, inputs, attrs);
}
export const localResponseNormalizationBackprop = op({
  localResponseNormalizationBackprop_
});
