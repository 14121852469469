/**
 * @license
 * Copyright 2022 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
import { searchSorted } from './search_sorted';
/**
 * Searches for where a value would go in a sorted sequence.
 *
 * This is not a method for checking containment (like javascript in).
 *
 * The typical use case for this operation is "binning", "bucketing", or
 * "discretizing". The values are assigned to bucket-indices based on the edges
 * listed in 'sortedSequence'. This operation returns the bucket-index for each
 * value.
 *
 * The index returned corresponds to the first edge greater than the value.
 *
 * The axis is not settable for this operation. It always operates on the
 * innermost dimension (axis=-1). The operation will accept any number of outer
 * dimensions.
 *
 * Note: This operation assumes that 'upperBound' is sorted along the
 * innermost axis, maybe using 'sort(..., axis=-1)'. If the sequence is not
 * sorted no error is raised and the content of the returned tensor is not well
 * defined.
 *
 * ```js
 * const seq = tf.tensor1d([0, 3, 9, 10, 10]);
 * const values = tf.tensor1d([0, 4, 10]);
 * const result = tf.upperBound(seq, values);
 * result.print(); // [1, 2, 5]
 * ```
 * @param sortedSequence: N-D. Sorted sequence.
 * @param values: N-D. Search values.
 * @return An N-D int32 tensor the size of values containing the result of
 *     applying upper bound to each value. The result is not a global index to
 *     the entire Tensor, but the index in the last dimension.
 * @doc {heading: 'Operations', subheading: 'Evaluation'}
 */
export function upperBound(sortedSequence, values) {
  return searchSorted(sortedSequence, values, 'right');
}
