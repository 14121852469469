import { Component } from "@angular/core";
import { ModeloService } from '../modelo/modelo.service';
import { CommonModule } from "@angular/common";
import { RouterLink, RouterLinkActive, RouterOutlet } from "@angular/router";

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [CommonModule, RouterLink, RouterOutlet, RouterLink, RouterLinkActive],
    templateUrl: './header.component.html',
})
export class HeaderComponent {
    modelos:any=[];
    constructor(public modeloService: ModeloService){
        this.getModelos();
    }
    getModelos() {
        this.modeloService.getModelos().subscribe((res:any)=>{
            const lista= res.data.filter((row:any)=> row.nombre.split(".")[1]==="txt")
            this.modelos=lista
            console.log(this.modelos)
        });
    }
}