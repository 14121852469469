/**
 * @license
 * Copyright 2022 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
export var RowPartitionType;
(function (RowPartitionType) {
  RowPartitionType[RowPartitionType["FIRST_DIM_SIZE"] = 0] = "FIRST_DIM_SIZE";
  RowPartitionType[RowPartitionType["VALUE_ROWIDS"] = 1] = "VALUE_ROWIDS";
  RowPartitionType[RowPartitionType["ROW_LENGTHS"] = 2] = "ROW_LENGTHS";
  RowPartitionType[RowPartitionType["ROW_SPLITS"] = 3] = "ROW_SPLITS";
  RowPartitionType[RowPartitionType["ROW_LIMITS"] = 4] = "ROW_LIMITS";
  RowPartitionType[RowPartitionType["ROW_STARTS"] = 5] = "ROW_STARTS";
})(RowPartitionType || (RowPartitionType = {}));
export function combineRaggedTensorToTensorShapes(raggedRank, shape, valueShape) {
  // Test for consistency of valueShape and shape specified.
  // If shape is unspecified and valueShape is specified, then copy
  // over the size from the valueShape dimension.
  let outputShape = new Array();
  if (valueShape == null && shape == null) {
    return outputShape;
  }
  if (shape == null) {
    // Here, value_shape must be of known size.
    while (outputShape.length < raggedRank + valueShape.length) {
      outputShape.push(-1);
    }
  } else {
    outputShape = shape.slice();
  }
  if (valueShape == null) {
    return outputShape;
  }
  // At this point, valueShape and output_shape have known ranks.
  if (raggedRank + valueShape.length !== outputShape.length) {
    throw new Error(`rt input.shape and shape=${shape} are incompatible: rt input.rank = ${raggedRank + valueShape.length}, but shape.rank = ${outputShape.length}`);
  }
  for (let i = 1; i < valueShape.length; ++i) {
    const valueDim = valueShape[i];
    const outputShapeDimIndex = outputShape[outputShape.length - valueShape.length + i];
    const outputShapeDim = outputShape[outputShapeDimIndex];
    if (valueDim >= 0) {
      if (outputShapeDim >= 0) {
        if (outputShapeDim !== valueDim) {
          throw new Error(`rt input.shape and shape=${shape} are incompatible: rt input.shape[${i + raggedRank}] = ${valueDim} but shape[${i + raggedRank}] = ${outputShapeDim}`);
        }
      } else {
        outputShape[outputShapeDimIndex] = valueDim;
      }
    }
  }
  return outputShape;
}
export function getRowPartitionTypesHelper(rowPartitionTypeStrings) {
  const stringToType = {
    'FIRST_DIM_SIZE': RowPartitionType.FIRST_DIM_SIZE,
    'VALUE_ROWIDS': RowPartitionType.VALUE_ROWIDS,
    'ROW_LENGTHS': RowPartitionType.ROW_LENGTHS,
    'ROW_SPLITS': RowPartitionType.ROW_SPLITS,
    'ROW_LIMITS': RowPartitionType.ROW_LIMITS,
    'ROW_STARTS': RowPartitionType.ROW_STARTS
  };
  const result = [];
  for (const typeStr of rowPartitionTypeStrings) {
    if (typeStr in stringToType) {
      result.push(stringToType[typeStr]);
    } else {
      break;
    }
  }
  return result;
}
export function getRaggedRank(rowPartitionTypes) {
  if (rowPartitionTypes.length === 0) {
    return 0;
  }
  if (rowPartitionTypes[0] === RowPartitionType.FIRST_DIM_SIZE) {
    return rowPartitionTypes.length - 1;
  }
  return rowPartitionTypes.length;
}
export function validateDefaultValueShape(defaultValueShape, valueShape) {
  if (defaultValueShape == null || valueShape == null) {
    return;
  }
  const defaultNDims = defaultValueShape.length;
  const valuesNDims = valueShape.length;
  if (defaultNDims >= valuesNDims) {
    throw new Error(`defaultValue.shape=${defaultValueShape} and ragged tensor flatValues.shape=${valueShape}, are incompatible: defaultValue.rank = ${defaultNDims} must be less than ragged tensor input flatValues.rank = ${valuesNDims})`);
  }
  for (let i = 0; i < Math.min(defaultNDims, valuesNDims - 1); ++i) {
    const defaultDim = defaultValueShape[i];
    const valueDim = valueShape[i + 1];
    if (defaultDim >= 0 && valueDim >= 0 && defaultDim !== 1 && defaultDim !== valueDim) {
      throw new Error(`defaultValue.shape=${defaultValueShape}, and ragged tensor input flatValues.shape=${valueShape} are incompatible: defaultValue.shape[${i - defaultValueShape.length}] = ${defaultDim} but ragged tensor input.flatValues.shape[${i - defaultValueShape.length}] = ${valueDim}`);
    }
  }
}
