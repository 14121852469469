/**
 * @license
 * Copyright 2022 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
import { ENGINE } from '../engine';
import { RaggedGather } from '../kernel_names';
import { convertToTensor } from '../tensor_util_env';
import { op } from './operation';
function raggedGather_(paramsNestedSplits, paramsDenseValues, indices, outputRaggedRank) {
  const $paramsNestedSplits = paramsNestedSplits.map((t, i) => convertToTensor(t, `tensors${i}`, 'raggedGather', 'int32'));
  const $paramsDenseValues = convertToTensor(paramsDenseValues, 'paramsDenseValues', 'raggedGather');
  const $indices = convertToTensor(indices, 'indices', 'raggedGather', 'int32');
  const inputs = {
    paramsNestedSplits: $paramsNestedSplits,
    paramsDenseValues: $paramsDenseValues,
    indices: $indices
  };
  const attrs = {
    outputRaggedRank
  };
  const result = ENGINE.runKernel(RaggedGather, inputs, attrs);
  return {
    outputNestedSplits: result.slice(0, result.length - 1),
    outputDenseValues: result[result.length - 1]
  };
}
export const raggedGather = /* @__PURE__ */op({
  raggedGather_
});
