/**
 * @license
 * Copyright 2020 Google Inc. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
// Required side effectful code for tfjs-core
// Set up Engine and ENV
import { getOrMakeEngine } from './engine';
getOrMakeEngine();
// Register backend-agnostic flags.
import './flags';
// Register platforms
import './platforms/platform_browser';
import './platforms/platform_node';
// Set up OpHandler
import { buffer } from './ops/buffer';
import { cast } from './ops/cast';
import { clone } from './ops/clone';
import { print } from './ops/print';
import { setOpHandler } from './tensor';
const opHandler = {
  buffer,
  cast,
  clone,
  print
};
setOpHandler(opHandler);
