import { Component, OnDestroy, OnInit } from "@angular/core";
import * as tf from '@tensorflow/tfjs';
import * as knnClassifier from '@tensorflow-models/knn-classifier';
import * as mobilenet from  '@tensorflow-models/mobilenet';
import { ModeloService } from "../modelo/modelo.service";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from "@angular/common";

@Component({
    selector: 'creacion-modelo',
    standalone: true,
    imports: [ReactiveFormsModule,MatIconModule, CommonModule],
    templateUrl: './creacion-modelo.component.html'
})
export class CreacionModeloComponent implements OnInit, OnDestroy{
    classifier: knnClassifier.KNNClassifier;
    labels: any = [];
    labelsArray:any = [];
    showForm: boolean = false;
    form!: FormGroup;
    modelos:any;
    videoRef: any;
    inputControl = new FormControl("");
    private isAlive2= true;

    constructor(public serviceModel: ModeloService,private fb: FormBuilder){
        this.form = this.fb.group({
            label: ['', Validators.required]
          });
        this.classifier = knnClassifier.create()
        this.app();
    }
    ngOnDestroy(): void {
        this.isAlive2=false;
    }
    ngOnInit() {
        this.videoRef = document.getElementById("webcam");
        console.log(this.videoRef);
        this.setupCamera();
    }

    net: any;
    webcam: any;
    async app(){
        this.net = await mobilenet.load();
        this.webcam = await tf.data.webcam(this.videoRef);
        while(this.isAlive2){
            const img = await this.webcam.capture();
            const result = await this.net.classify(img);
            const activation = this.net.infer(img, "conv_preds");
            let result2:any;
            try {
                result2 = await this.classifier.predictClass(activation);
                console.log(result2)
                const classes = ["Undefined"].concat(this.labelsArray)
                console.log(classes)
                document.getElementById("console2")?document.getElementById("console2")!.innerText="Prediction: " + classes[result2.label] + ", probability: " + result2.confidences[result2.label]:""; 
            } catch (error) {
                console.log("Modelo no configurado aún"+error);
                result2= {};
                document.getElementById("console2")?document.getElementById("console2")!.innerText="Modelo no entrenado":"";                   
            }
            try{
            
            }
            catch(err) {
            }
            img.dispose();
            await tf.nextFrame();
            }
    }
    
    setupCamera(){
        navigator.mediaDevices.getUserMedia({
            video: {width: 300, height:250},
            audio: false
        }).then(stream=>{
            console.log(stream);
            this.videoRef.srcObject = stream;
        })
    }

    async addExample(classId:any){
        console.log('Added example');
        const img = await this.webcam.capture();
        const activation = this.net.infer(img, true);
        this.classifier.addExample(activation, classId);
        img.dispose();
    }

    saveKnn() {
        this.serviceModel.guardarModelos(this.classifier, this.inputControl.value).subscribe(result=>{
            console.log(result)
            this.inputControl.reset()
            this.labels=[];
            this.labelsArray=[];
        })
        this.serviceModel.guardarJsonModelo(this.labels.reduce((acc:any, val:any)=>acc+val.nombre+",", "").slice(0,-1), this.inputControl.value!).subscribe(result=>{
            console.log(result);
    })
};

    loadKnn = () => {
        this.serviceModel.getModelos().subscribe(result=>{
            this.modelos = result;
            console.log(this.modelos);
        });
    }
    

    // loadKnn = async ()=>{
    //     const storageKey = "knnClassifier";
    //     let datasetJson = localStorage.getItem(storageKey);
    //     this.classifier.setClassifierDataset(Object.fromEntries(JSON.parse(datasetJson).map(([label, data, shape]) => [label, tf.tensor(data, shape)])));
    // };

    toggleForm(){
        this.showForm = !this.showForm;
    }

    submitForm(): void {
        const posicion=this.labels.length
        this.labels.push({"id":posicion, "nombre": this.form.value.label})
        console.log(this.labels)
        this.labelsArray.push(this.form.value.label)
        this.toggleForm(); // Optionally hide the form after submission
    }


}

