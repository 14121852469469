/**
 * @license
 * Copyright 2020 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
// Import shared functionality from tfjs-backend-cpu without triggering
// side effects.
// tslint:disable-next-line: no-imports-from-dist
import * as shared from '@tensorflow/tfjs-backend-cpu/dist/shared';
const {
  addImpl: addImplCPU,
  bincountImpl: bincountImplCPU,
  bincountReduceImpl: bincountReduceImplCPU,
  bitwiseAndImpl: bitwiseAndImplCPU,
  castImpl: castImplCPU,
  ceilImpl: ceilImplCPU,
  concatImpl: concatImplCPU,
  equalImpl: equalImplCPU,
  expImpl: expImplCPU,
  expm1Impl: expm1ImplCPU,
  floorImpl: floorImplCPU,
  gatherNdImpl: gatherNdImplCPU,
  gatherV2Impl: gatherV2ImplCPU,
  greaterImpl: greaterImplCPU,
  greaterEqualImpl: greaterEqualImplCPU,
  lessImpl: lessImplCPU,
  lessEqualImpl: lessEqualImplCPU,
  linSpaceImpl: linSpaceImplCPU,
  logImpl: logImplCPU,
  maxImpl: maxImplCPU,
  maximumImpl: maximumImplCPU,
  minimumImpl: minimumImplCPU,
  multiplyImpl: multiplyImplCPU,
  negImpl: negImplCPU,
  notEqualImpl: notEqualImplCPU,
  prodImpl: prodImplCPU,
  raggedGatherImpl: raggedGatherImplCPU,
  raggedRangeImpl: raggedRangeImplCPU,
  raggedTensorToTensorImpl: raggedTensorToTensorImplCPU,
  rangeImpl: rangeImplCPU,
  rsqrtImpl: rsqrtImplCPU,
  scatterImpl: scatterImplCPU,
  sigmoidImpl: sigmoidImplCPU,
  simpleAbsImpl: simpleAbsImplCPU,
  sliceImpl: sliceImplCPU,
  sparseFillEmptyRowsImpl: sparseFillEmptyRowsImplCPU,
  sparseReshapeImpl: sparseReshapeImplCPU,
  sparseSegmentReductionImpl: sparseSegmentReductionImplCPU,
  sqrtImpl: sqrtImplCPU,
  staticRegexReplaceImpl: staticRegexReplaceImplCPU,
  stridedSliceImpl: stridedSliceImplCPU,
  stringNGramsImpl: stringNGramsImplCPU,
  stringSplitImpl: stringSplitImplCPU,
  stringToHashBucketFastImpl: stringToHashBucketFastImplCPU,
  subImpl: subImplCPU,
  tileImpl: tileImplCPU,
  topKImpl: topKImplCPU,
  transposeImpl: transposeImplCPU,
  uniqueImpl: uniqueImplCPU
} = shared;
export { addImplCPU, bincountImplCPU, bincountReduceImplCPU, bitwiseAndImplCPU, castImplCPU, ceilImplCPU, concatImplCPU, equalImplCPU, expImplCPU, expm1ImplCPU, floorImplCPU, gatherNdImplCPU, gatherV2ImplCPU, greaterEqualImplCPU, greaterImplCPU, lessEqualImplCPU, lessImplCPU, linSpaceImplCPU, logImplCPU, maxImplCPU, maximumImplCPU, minimumImplCPU, multiplyImplCPU, negImplCPU, notEqualImplCPU, prodImplCPU, raggedGatherImplCPU, raggedRangeImplCPU, raggedTensorToTensorImplCPU, scatterImplCPU, sigmoidImplCPU, simpleAbsImplCPU, sliceImplCPU, sparseFillEmptyRowsImplCPU, sparseReshapeImplCPU, sparseSegmentReductionImplCPU, sqrtImplCPU, staticRegexReplaceImplCPU, stridedSliceImplCPU, stringNGramsImplCPU, stringSplitImplCPU, stringToHashBucketFastImplCPU, subImplCPU, rangeImplCPU, rsqrtImplCPU, tileImplCPU, topKImplCPU, transposeImplCPU, uniqueImplCPU };
