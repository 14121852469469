/**
 * @license
 * Copyright 2020 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
import { Pool2DProgram } from '../pool_gpu';
export function maxPoolWithArgmaxImpl(x, includeBatchInIndex, convInfo, backend) {
  let program = new Pool2DProgram(convInfo, 'max', false);
  const poolOutput = backend.runWebGLProgram(program, [x], 'float32');
  program = new Pool2DProgram(convInfo, 'max', true, true, includeBatchInIndex);
  const indexOutput = backend.runWebGLProgram(program, [x], 'float32');
  return [poolOutput, indexOutput];
}
