/**
 * @license
 * Copyright 2020 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
import { env, Neg } from '@tensorflow/tfjs-core';
import { negImplCPU } from '../kernel_utils/shared';
import { CHECK_NAN_SNIPPET, UnaryOpProgram } from '../unaryop_gpu';
import { UnaryOpPackedProgram } from '../unaryop_packed_gpu';
const NEG = CHECK_NAN_SNIPPET + `
  return -x;
`;
const NEG_PACKED = `
  vec4 result = -x;
  bvec4 isNaN = isnan(x);

  result.r = isNaN.r ? x.r : result.r;
  result.g = isNaN.g ? x.g : result.g;
  result.b = isNaN.b ? x.b : result.b;
  result.a = isNaN.a ? x.a : result.a;

  return result;
`;
// This doesn't use unaryKernelFunc because negImplCPU is not of type
// SimpleUnaryKernelImplCPU.
export function neg(args) {
  const {
    inputs,
    backend
  } = args;
  const {
    x
  } = inputs;
  if (backend.shouldExecuteOnCPU([x])) {
    const xData = backend.texData.get(x.dataId);
    const [outValues, newShape] = negImplCPU(xData.values, x.shape, x.dtype);
    return backend.makeTensorInfo(newShape, x.dtype, outValues);
  }
  let program;
  if (env().getBool('WEBGL_PACK_UNARY_OPERATIONS')) {
    program = new UnaryOpPackedProgram(x.shape, NEG_PACKED);
  } else {
    program = new UnaryOpProgram(x.shape, NEG);
  }
  return backend.runWebGLProgram(program, [x], x.dtype);
}
export const negConfig = {
  kernelName: Neg,
  backendName: 'webgl',
  kernelFunc: neg
};
